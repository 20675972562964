var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: "fill-current  text-".concat(_vm.color),
    attrs: {
      "height": "".concat(_vm.height, "px"),
      "width": "".concat(_vm.width, "px"),
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11.191 5.47556L8.56431 8.05778C8.28096 7.92129 7.95849 7.88936 7.65388 7.96762C7.34927 8.04589 7.08213 8.22931 6.89967 8.48548C6.71721 8.74165 6.63117 9.05407 6.65677 9.36753C6.68236 9.68099 6.81794 9.97531 7.03954 10.1985C7.26115 10.4217 7.5545 10.5593 7.86778 10.5871C8.18105 10.6149 8.49407 10.5311 8.75152 10.3505C9.00897 10.1698 9.19428 9.90398 9.27469 9.59993C9.35511 9.29588 9.32545 8.97319 9.19097 8.68889L11.8221 6.10667L11.191 5.47556Z"
    }
  }), _c('path', {
    attrs: {
      "d": "M7.99989 1.88889C6.6011 1.88729 5.23106 2.28602 4.05158 3.03799C2.87211 3.78996 1.93246 4.86376 1.34362 6.13258C0.754781 7.40139 0.54134 8.81221 0.728507 10.1984C0.915675 11.5846 1.49563 12.8883 2.39989 13.9556L2.53322 14.1111H13.4666L13.5999 13.9556C14.5041 12.8883 15.0841 11.5846 15.2713 10.1984C15.4584 8.81221 15.245 7.40139 14.6562 6.13258C14.0673 4.86376 13.1277 3.78996 11.9482 3.03799C10.7687 2.28602 9.39868 1.88729 7.99989 1.88889ZM13.0399 13.2222H2.95989C2.16241 12.2243 1.68111 11.011 1.57767 9.73778H3.111V8.84889H1.57767C1.65209 7.43766 2.1912 6.09067 3.111 5.01778L4.19989 6.10667L4.82655 5.48L3.74655 4.38667C4.80578 3.45046 6.14501 2.89102 7.55544 2.79556V4.35111H8.44433V2.8C10.0023 2.91299 11.4659 3.58917 12.5619 4.70228C13.6578 5.81539 14.3111 7.28933 14.3999 8.84889H12.8532V9.73778H14.4221C14.3187 11.011 13.8374 12.2243 13.0399 13.2222Z"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }